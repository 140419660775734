import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContentFull, ContentHeader } from "components/layout";
import { useCopyToClipboard } from "components/shared/hooks";
import { Input } from "components/ui/input";
import { defaultLinkStyle } from "components/ui/link";
import { SecondaryButton } from "components/ui/secondary-button";
import { ActionType, useGetCalendarSubscriptionQuery } from "generated/graphql";
import React from "react";
import { useIsAllowed, useTenantSettings } from "store/selectors/hooks";
import { UserCheckboxPref, UserNumericPref } from "./account-settings-checkbox";

export const AccountSettingsNotifications: React.FC<unknown> = () => {
    const query = useGetCalendarSubscriptionQuery();
    const [copy, isCopied] = useCopyToClipboard();
    const ownedReferralSources = !!useTenantSettings().ownedReferralSources;
    const viewOtherClients = useIsAllowed(ActionType.ViewClient);

    return (
        <div className="space-y-4 lg:space-y-8">
            <ContentFull>
                <ContentHeader>Notifications</ContentHeader>
                <div className="space-y-4">
                    <UserCheckboxPref
                        field="clientUpdateNotifications"
                        label="Notify me when my clients are updated"
                        description="Receive an email when clients assigned to me are updated"
                    />
                    {ownedReferralSources && viewOtherClients ? (
                        <UserCheckboxPref
                            field="clientUpdateReferralOwnerNotifications"
                            label="Notify me when my referral sources' clients are updated"
                            description="Receive an email when clients with one of my referral sources are updated"
                        />
                    ) : null}
                    <UserCheckboxPref
                        field="taskAssignmentNotifications"
                        label="Notify me when a task is assigned to me"
                        description="Receive email notifications when a task is assigned to me."
                    />
                    <UserCheckboxPref
                        field="reminderEnabled"
                        label="Enable task reminders pop-up window"
                        description="Show a reminders window for tasks when they are due"
                    />
                </div>
            </ContentFull>
            <QueryRenderer name="Calendar subscription" query={query}>
                {data => (
                    <ContentFull>
                        <ContentHeader>Calendar</ContentHeader>
                        <div className="space-y-4">
                            <div>
                                Access your scheduled tasks in your calendar application
                                on your computer or phone. For more information, see our{" "}
                                <a
                                    className={`twoverride ${defaultLinkStyle}`}
                                    href="https://docs.seniorplace.io/article/48-add-calendar-to-your-computer-or-device"
                                    rel="noreferrer"
                                    target="_blank">
                                    instructions on adding your tasks to your calendar
                                </a>
                                .
                            </div>
                            <a
                                className={`flex space-x-2 items-center twoverride ${defaultLinkStyle}`}
                                href={data.getCalendarSubscription.calendarUrl}>
                                <CalendarDaysIcon className="h-6 w-6" />
                                <div>Open calendar</div>
                            </a>

                            <UserNumericPref
                                field="taskDuration"
                                label="Duration in minutes of each task on your calendar"
                                description="Amount of time each task should take on your calendar."
                            />
                            <div>
                                <div className="font-bold">Advanced</div>
                                <div className="italic">
                                    If you need to copy the calendar link directly, use
                                    the option below
                                </div>
                            </div>
                            <div className="md:flex sm:space-y-2 md:space-y-0 md:space-x-2">
                                <div className="md:flex-1">
                                    {isCopied ? (
                                        <div className="rounded text-center bg-green-100 text-green-600 text-lg py-2 font-bold">
                                            Link copied!
                                        </div>
                                    ) : (
                                        <Input
                                            readOnly
                                            value={
                                                data.getCalendarSubscription.calendarUrl
                                            }
                                        />
                                    )}
                                </div>
                                <div className="md:flex-0">
                                    <SecondaryButton
                                        onClick={() =>
                                            copy(data.getCalendarSubscription.calendarUrl)
                                        }>
                                        Copy link to clipboard
                                    </SecondaryButton>
                                </div>
                            </div>
                        </div>
                    </ContentFull>
                )}
            </QueryRenderer>
        </div>
    );
};
